.about-wrap {
  margin: 80px auto 32px;
  width: 100%;
}

.about-wrap div {
  margin: auto;
  width: 100%;
  /* padding: 58px; */
  /* max-width: 100%; */
}

.about-wrap div > div {
  gap: 24px;
}

.about-wrap h3 {
  text-transform: uppercase;
  color: #ff8d68;
  margin-bottom: 8px;
}
.about-wrap h2 {
  margin: 0;
  color: black;
}
.about-wrap p {
  margin-bottom: 48px;
}
.about-p {
  padding: 0 16px;
}

.about-wrap img {
  width: 70%;
}
.about-wrap button {
  background: #ff8d68;
  color: white;
  border-radius: 40px;
}

.about-card-cont {
  display: block !important;
  text-align: center;
}
.about-card-dots-cont {
  display: none;
}
.about-card-cont > * {
  margin: 0 auto 16px !important;
}

@media only screen and (min-width: 650px) {
  .about-wrap-wrap {
    margin: 56px auto;
  }
  .about-wrap div {
    margin: 0;
    padding: 0 24px;
    /* max-width: 50%; */
    /* padding: 24px; */
  }
  .about-wrap img {
    width: 47%;
  }
  .about-wrap h3 {
    font-size: 14px;
  }
  .about-wrap h2 {
    font-size: 28px;
  }
  .about-wrap p {
    font-size: 14px;
    line-height: 170%;
    max-width: 500px;
    margin: 16px auto 32px;
  }
}

.car-dot {
  width: 18px !important;
  height: 18px !important;
  padding: 0 !important;
  border-radius: 100%;
  border: 2px solid #ff8d68;
}
.active-dot {
  width: 24px !important;
  height: 24px !important;
  background: #ff8d68;
}

@media only screen and (min-width: 800px) {
  .about-card-cont {
    display: flex !important;
  }
  .about-card-dots-cont {
    display: flex;
  }
}

@media only screen and (min-width: 900px) {
  .about-wrap {
    margin: 120px auto;
  }
  .about-wrap div {
    margin: 0;
  }
  .about-wrap h3 {
    font-size: 14px;
  }
  .about-wrap h2 {
    font-size: 28px;
  }
  .about-wrap p {
    font-size: 14px;
    line-height: 170%;
  }
}

@media only screen and (min-width: 1200px) {
  .about-wrap {
    margin: 120px auto;
  }
  .about-wrap div {
  }
  .about-wrap h3 {
    font-size: 16px;
  }
  .about-wrap h2 {
    font-size: 34px;
  }
  .about-wrap p {
    font-size: 16px;
    margin-bottom: 48px;
  }
}
