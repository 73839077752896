.action-card {
  background: white;
  border-radius: 12px;
  padding: 24px !important;
  gap: 0 !important;
  transition: 0.2s ease-in-out;
  width: 250px;
  height: 300px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  gap: 0 !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
.action-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}

.action-card-icon {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  padding: 24px;
  color: #4f84df;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
.action-card:hover .action-card-icon {
  background: rgba(79, 132, 223, 0.1);
}

.action-card h5 {
  transition: 0.2s ease-in-out;
  margin: 24px 0 12px;
}
.action-card:hover h5 {
  color: #ff8d68;
}

.action-card p {
  margin: 0;
}
@media only screen and (max-width: 1000px) {
  .action-card {
    display: inline-block;
    margin: 24px 0 24px 24px;
    text-align: center;
    white-space: normal;
  }

  .action-card-icon {
    margin: auto;
    white-space: nowrap;
    width: 80px;
    height: 80px;
    padding: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .action-card {
    width: 200px !important;
    height: 250px !important;
    /* padding: 16px !important; */
  }
  .action-card p {
    font-size: 12px;
    line-height: 150%;
  }
}
