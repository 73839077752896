.landign-cont-wrap {
  padding-top: 24px;
  width: 100% !important;
  max-width: 100% !important;
}

.landing-text-wrap {
  text-align: left;
  margin-right: 65px;
}
.landing-text-wrap h2 {
  font-size: 53px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.landing-text-wrap h1 {
  font-weight: 400;
  font-size: 24px;
  color: white;
  margin: 32px 0 32px;
}
.landing-text-wrap button {
  font-size: 20px;
  height: 56px;
  margin-right: 24px;
  white-space: nowrap;
}

.landing-button-wrap > *:not(:first-child),
.landing-button-wrap > *:not(:first-child) > * {
  font-size: 16px;
  margin-right: 12px;
  background: none;
  color: white;
  box-shadow: none;
  border-radius: 10px;
}
.landing-button-wrap > *:not(:first-child) > *:hover {
  background: rgba(255, 255, 255, 0.1);
}

.video-wrap {
  width: 80%;
  max-width: 700px !important;
}
.video-cont {
  position: relative;
  width: Calc(100% + 10px);
  margin-left: -5px;
  padding-bottom: 56.25%;
  overflow: hidden;
  border: 5px solid white;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}

.video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.quotes {
  position: absolute;
  color: rgba(255, 255, 255, 0.1);
  width: 64px;
}
.quote-1 {
  top: 0;
  left: -24px;
  transform: scaleX(-1);
}
.quote-2 {
  bottom: 0;
  right: -24px;
}
.landing-wrap h3 {
  color: white;
  font-weight: 300;
  font-size: 32px;
  margin: 48px 0;
}

@media only screen and (max-width: 1200px) {
  .landing-text-wrap {
    margin-right: 48px;
  }
  .landing-text-wrap h2 {
    font-size: 40px;
  }
  .landing-text-wrap h1 {
    font-size: 24px;
    margin: 32px 0 32px;
  }
  .landing-text-wrap button {
    font-size: 16px;
    height: 48px;
  }
}
@media only screen and (max-width: 1000px) {
  .landing-text-wrap {
    margin-right: 24px;
    /* width: 50%; */
  }
  .landing-text-wrap h2 {
    font-size: 30px;
  }
  .landing-text-wrap h1 {
    font-size: 18px;
    margin: 24px 0 32px;
  }
  .landing-text-wrap button {
    font-size: 16px;
    height: 40px;
  }
  .hide-landng-button {
    display: none;
  }
  .landing-wrap h3 {
    font-size: 30px;
  }
  .landing-wrap h3 {
    margin: 32px 0;
  }
}

@media only screen and (max-width: 700px) {
  .landign-cont-wrap {
    flex-direction: column-reverse;
    padding: 24px 0;
  }
  .landing-text-wrap {
    text-align: center;
    margin: 24px 0 24px;
  }
  .landing-text-wrap h1 {
    font-size: 18px;
    margin: 16px 0 24px;
  }
  .landing-text-wrap button {
    font-size: 16px;
    height: 40px;
    width: 240px;
    margin: 0;
  }
  .landing-button-wrap {
    justify-content: center;
  }

  .video-wrap {
    width: 100%;
  }
  .video-cont {
    border-width: 2px;
  }
  .landing-wrap h3 {
    font-size: 20px;
    margin: 36px auto;
  }
}
