footer p {
  color: white;
}

footer a {
  padding: 4px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-left: 8px;
  transition: 0.2s ease-in-out;
}
footer a:hover {
  background: rgba(255, 255, 255, 0.1);
}

footer svg {
  height: 24px;
  width: 24px;
  display: block;
  cursor: pointer;
}

@media only screen and (max-width: 720px) {
  .footer-wrap {
    flex-direction: column-reverse;
  }
}
