body {
  background: url(media/tile.svg) repeat;
}

/* Font----------- */
h2 {
  font-size: 20px;
  color: #2a46ff;
}

p {
  font-size: 1rem;
  color: #838a96;
  line-height: 27px;
}
a {
  text-decoration: none;
}

.App {
  text-align: center;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}
.App div {
  box-sizing: border-box;
}

button {
  background: white;
  color: #2a46ff;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  outline: inherit;
  transition: 0.2s ease;
}
button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09),
    0px 15px 25px rgba(0, 0, 0, 0.12);
}
section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8px;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  background: white;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);

  padding-top: 180px !important;
  border-radius: 0 0 20px 20px;
}

section > *:last-child,
header > *,
.action-cards,
.landign-cont-wrap,
footer {
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 24px 16px;
  box-sizing: border-box;
}

.doc-button {
  width: 100%;
  /* height: 56px; */
  min-height: 56px;
  background: #ff8d68;
  color: white;
  border-radius: 10px;
  padding: 8px 24px;
  box-sizing: border-box;

  cursor: pointer;
  transition: 0.2s ease-in-out;
  /* padding: 24px; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09), 0px 5px 6px rgba(0, 0, 0, 0.08);
}
.doc-button p {
  color: white;
  margin: 4px;
  font-size: 18px;
  font-weight: 600;
}
.doc-button svg {
  width: 24px;
}
.doc-button:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}

/* Animations------------------ */
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translatey(80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-up-sml {
  0% {
    opacity: 0;
    transform: translatey(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

.float-in-place {
  animation: float 7s ease-in-out infinite;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-8px) rotate(8deg);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
}

/* Flexbox--------------------- */
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-btw {
  display: flex;
  justify-content: space-between;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-top {
  display: flex;
  align-items: flex-start;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

/* mobile------------------------------- */
.desktop-only {
  display: none;
}

.mobile-only {
  display: flex;
}

/* Tablet---- */
@media only screen and (min-width: 600px) {
  section > *:last-child,
  header > *,
  .action-cards,
  .landign-cont-wrap,
  footer {
    max-width: 800px;
    padding: 36px 16px;
  }
  section {
    padding: 0 24px;
  }
  .content-wrapper {
    padding-top: 120px !important;
    border-radius: 0 0 20px 20px;
  }

  .doc-button p {
    font-size: 24px;
  }
  .doc-button svg {
    width: 34px;
  }

  /* mobile------------------------------- */
  .desktop-only {
    display: flex;
  }

  .mobile-only {
    display: none;
  }
}
/* Desktop---- */
@media only screen and (min-width: 900px) {
  section > *:last-child,
  header > *,
  .action-cards,
  footer {
    max-width: 1400px;
    padding: 64px 36px;
  }
  .content-wrapper {
    border-radius: 0 0 20px 20px;
  }
  section {
    padding: 0 48px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
}
