.doc-page-grid {
  display: grid;
  grid-template-columns: 1fr 256px;
}
.docs-page-wrapper {
  text-align: left;
  padding: 32px 80px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 860px;
}

.docs-page-wrapper h1 {
  font-size: 32px;
  color: #202124;
}
.docs-page-wrapper span {
  color: #1a73e8;
  font-style: italic;
}
.docs-page-wrapper h2 {
  font-size: 24px;
  color: #202124;
  font-weight: 500;
  margin-top: 48px;
}
.docs-page-wrapper h3 {
  font-size: 18px;
  color: #1a73e8;
}
.docs-page-wrapper ul {
  margin: 32px 0;
}
.docs-page-wrapper ul ul {
  margin: 16px 0;
}
.docs-page-wrapper li {
  color: #838a96;
  font-size: 18px;
  margin: 4px 0;
  line-height: 30px;
}
.docs-page-wrapper li b {
  color: #1a73e8;
  font-weight: 400;
}
.nav-wrap {
  margin: 56px 0;
  grid-gap: 24px;
}
.nav-wrap a {
  padding: 12px;
  color: #1a73e8;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 8px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #1a73e8;
}
.nav-wrap a:hover {
  opacity: 0.5;
}
.nav-wrap svg {
  height: 24px;
  display: block;
  margin-bottom: -3px;
}

.docs-toc {
  padding-top: 50px;
  text-align: left;
}
.docs-toc > div {
  align-self: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.docs-toc a {
  padding-left: 12px;
  white-space: nowrap;
  color: #5f6368;
  height: 24px;
  border-left: 3px solid #dddfe4;
}
.active-nav-item {
  border-bottom: 2px solid white;
}

@media only screen and (max-width: 1200px) {
  .doc-page-grid {
    grid-template-columns: 1fr 180px;
  }
  .docs-page-wrapper {
    padding: 32px;
  }
}
@media only screen and (max-width: 1000px) {
  .doc-page-grid {
    grid-template-columns: 1fr;
  }
  .docs-page-wrapper {
    padding: 48px;
  }

  .docs-toc {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .docs-page-wrapper {
    padding: 16px;
  }
  .nav-wrap a {
    font-size: 14px;
    padding: 8px;
  }
  .active-nav-item {
    border-bottom: 0;
    color: #1a73e8 !important;
  }
}
