.docs-container.content-wrapper {
  border-radius: 20px !important;
  margin-top: 24px;
  padding: 0 !important;
  overflow: hidden;
}

.docs-container {
  display: grid;
  grid-template-columns: 256px 1fr;
  height: 100%;
}

.docs-nav {
  padding: 24px 0;
  background: #e8eaed;
  border-right: 1px solid #dddfe4;
  /* max-height: Calc(100vh - 128px);
  overflow-y: scroll;
  position: fixed; */
}

.nav-section-div {
  font-size: 12px;
  color: #5f6368;
  height: 32px;
  padding: 0 0 0 24px;
  background: #f6f6f7;
  border-top: 1px solid #dddfe4;
  border-bottom: 1px solid #dddfe4;
  text-align: left;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-top: 12px;
}
.nav-sec-wrap {
  transition: 0.2s ease-in-out;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
}
.nav-sec-open {
  border-top: 1px solid #dddfe4;
  border-bottom: 1px solid #dddfe4;
  padding-bottom: 12px;
}
.nav-sec-wrap > *:hover {
  background: #dddfe4;
}

.nav-sec-wrap a {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.nav-sec-wrap button {
  background: none;
  margin: 0 12px;
  height: auto;
  padding: 6px !important;
  color: #202124;
}
.nav-sec-wrap button:hover {
  box-shadow: none;
  opacity: 0.5;
}
.nav-sec-wrap button.nav-up {
  transform: rotate(180deg);
}
.nav-sec-wrap button svg {
  width: 24px;
  display: block;
  opacity: 0.5;
}

.docs-nav .docs-link {
  margin-top: 8px;
  padding: 0 0 0 24px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #202124;
  font-weight: 500;
  font-size: 15px;
}
.docs-nav .docs-link-sub {
  margin-top: 0;
  padding: 0 0 0 38px;
  height: 34px;
  font-size: 14px;
  color: #5f6368;
}

@media only screen and (max-width: 760px) {
  .docs-container {
    grid-template-columns: 200px 1fr;
  }
}
@media only screen and (max-width: 600px) {
  .docs-container {
    grid-template-columns: 1fr;
  }
  .docs-container .docs-nav {
    display: none;
  }
}
