header {
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  z-index: 100;
  padding: 0 24px;
  box-sizing: border-box;
  /* position: fixed; */
}
header > * {
  padding: 0 !important;
}

header img {
  height: 32px;
}

header a,
header li {
  text-decoration: none;
  list-style-type: none;
}

header a,
header .fo-link {
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 24px;
  transition: 0.2s ease-in-out;
}
header a:hover,
header .fo-link:hover {
  opacity: 0.5;
}
header button {
  font-weight: 400;
  background: none;
  border: 1px solid white;
  color: white;
  margin-left: 83px;
}
header button :hover {
  background: rgb(255, 255, 255, 0.1);
}

.modile-button {
  height: 40px;
  width: 40px;
  padding: 6px;
  color: white;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
}
.modile-button:hover {
  background: rgba(255, 255, 255, 0.01);
}
.sidenav-cont-blk,
.sidenav-cont-nav {
  display: none;
}

.follow-cont-blk {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  top: 0;
  left: 0;
  width: max(100vw, 100%);
  height: 100vh;
  z-index: 1000;
  animation: mob-back 0.2s ease-in-out forwards;
  max-width: 100%;
}

@keyframes mob-back {
  0% {
    opacity: 0;
    background: rgba(0, 0, 0, 0);
  }

  100% {
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
  }
}

.follow-card {
  background: white;
  border-radius: 10px;
  padding: 24px 32px;
  margin: 16px;
  max-width: 400px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}

.follow-card div {
  margin-top: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.follow-card a {
  color: #4f84df;
  padding: 12px !important;
  border-radius: 10px;
  background: #e8eaed;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: 56px;
  margin: 8px;
}
.follow-card a:hover {
  background: rgba(79, 132, 223, 0.1);
}

.follow-card svg {
  display: block;
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 700px) {
  header button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 600px) {
  header {
    padding: 0 8px;
  }
  header img {
    height: 24px;
  }
  .follow-card div {
    flex-direction: column;
  }
  .follow-card a {
    display: flex;
  }
  .docs-container.content-wrapper {
    margin-top: 8px !important;
  }
  .sidenav-cont-blk {
    display: block;
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    animation: mob-back 0.2s ease-in-out forwards;
  }

  .sidenav-cont-nav {
    display: block;
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    z-index: 1001;
    width: 256px;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 32px;
    background: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09),
      0px 15px 25px rgba(0, 0, 0, 0.12);
    opacity: 0;
    animation: mob-side 0.3s ease-in-out forwards;
  }
  @keyframes mob-side {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .sidenav-cont-nav li {
    padding: 8px 0 8px 24px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /* .sidenav-cont-nav li:hover {
    opacity: 0.6;
  } */

  .sidenav-cont-nav .top-level {
    color: #202124;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    height: 40px;
    font-weight: 500;
    text-align: left;
    width: 100%;
    padding: 0;
  }
  .docssvg {
    width: 24px;
    height: 24px;
    display: block;
    color: #5f6368;
  }
  .top-level-li button {
    height: 100%;
    display: flex;
    align-items: center;

    /* width: 40px; */
    padding: 6px 16px 6px 6px;
    color: #5f6368;
    border: none;
  }
  .top-level-li button:hover {
    box-shadow: none;
  }

  .sidenav-cont-nav .docs-nav {
    padding-top: 0;
  }
  .sidenav-cont-nav .docs-nav button {
    border: none;
    margin-right: 16px;
  }
  .sidenav-cont-nav a.docs-link {
    color: #5f6368;
    height: 38px;
    margin: 0;
  }
  .sidenav-cont-nav .docs-link,
  .sidenav-cont-nav .nav-section-div {
    padding-left: 32px !important;
  }
  .sidenav-cont-nav .docs-link-sub {
    padding-left: 48px !important;
  }
  .sidenav-cont-nav a.docs-link-sub {
    font-weight: 400;
  }
  .sidenav-cont-nav .nav-section-div {
    margin-top: 4px;
  }

  li.top-level-li.open {
    /* border-top: 1px solid #5f6368; */
    border-bottom: 1px solid #dddfe4;
    background: #e8eaed;
  }
  li.top-level-li.open svg {
    transform: rotate(180deg);
  }
  .docs-nav {
    /* border-bottom: 1px solid #5f6368; */
  }
}
