.content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  flex-direction: column-reverse;
  text-align: center;
  margin: 24px auto;
}

.content-wrap div {
  margin: auto;
  padding: 16px;
  max-width: 100%;
}

.content-wrap h3 {
  text-transform: uppercase;
  color: #ff8d68;
  margin-bottom: 8px;
}
.content-wrap h2 {
  margin: 0;
  color: black;
}
.content-wrap p {
  margin-bottom: 48px;
}

.content-wrap img {
  width: 80%;
  margin-bottom: 24px;
}
.content-wrap button {
  background: #ff8d68;
  color: white;
  border-radius: 40px;
}
@media only screen and (min-width: 650px) {
  .content-wrap {
    align-items: center;
    justify-content: space-between;
    margin: auto;
    text-align: left;
    margin: 48px auto;
    flex-direction: row;

    max-width: 1000px;
  }
  .rev-content-wrap {
    flex-direction: row-reverse;
    text-align: right;
  }
  .content-wrap div {
    margin: 0;
    padding: 0 24px;
    max-width: 50%;
    /* padding: 24px; */
  }
  .content-wrap img {
    width: 47%;
    margin-bottom: 0;
  }
  .content-wrap h3 {
    font-size: 14px;
  }
  .content-wrap h2 {
    font-size: 28px;
  }
  .content-wrap p {
    font-size: 14px;
    line-height: 170%;
    margin-bottom: 32px;
  }
}
@media only screen and (min-width: 900px) {
  .content-wrap {
    margin: 64px auto;
  }
  .content-wrap div {
    margin: 0;
    padding: 0 48px;
    max-width: 50%;
    /* padding: 24px; */
  }
  .content-wrap h3 {
    font-size: 14px;
  }
  .content-wrap h2 {
    font-size: 28px;
  }
  .content-wrap p {
    font-size: 14px;
    line-height: 170%;
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: 1200px) {
  .content-wrap {
    margin: 80px auto;
  }
  .content-wrap div {
    padding: 48px;
    max-width: 40%;
  }
  .content-wrap img {
    width: 53%;
  }
  .content-wrap h3 {
    font-size: 16px;
  }
  .content-wrap h2 {
    font-size: 34px;
  }
  .content-wrap p {
    font-size: 16px;
    margin-bottom: 48px;
  }
}
