.action-cards {
  position: relative;
  z-index: 12;
  width: 100%;
  display: block;
  margin-bottom: -8px;
  padding: 0 !important;
}
.topper-svg {
  position: relative;
  z-index: 12;
  width: 100%;
  z-index: ;
}

.action-cards-wrap {
  gap: 36px;
  margin: auto;
  position: relative;
  z-index: 13;
  margin-bottom: -180px;
}

@media only screen and (max-width: 1200px) {
  .action-cards-wrap {
    gap: 24px;
  }
}

@media only screen and (max-width: 1000px) {
  .action-cards-wrap {
    display: block;
    margin-left: 0px;
    white-space: nowrap;
    overflow-y: scroll;
    overflow-x: visible;
    width: 100vw;
  }

  .action-cards-wrap > *:last-child {
    margin-right: 36px;
  }
}
