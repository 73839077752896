.Card {
  background: white;
  border-radius: 12px;
  padding: 32px !important;
  gap: 0 !important;
  transition: 0.2s ease-in-out;
  max-width: 300px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}

.Card:hover {
  transform: scale(1.02);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}
.active-card {
  padding: 48px 32px !important;
  max-width: 350px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}

.Card img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: 3px solid #4f84df;
  overflow: hidden;
  object-fit: fill;
  display: block;
  transition: 0.2s ease-in-out;
}
.active-card img {
  height: 120px;
  width: 120px;
}
.Card h4 {
  margin-bottom: 0;
}
.Card h5 {
  color: #ff8d68;
  margin: 8px 0 0;
}
.Card p {
  max-width: 230px;
  margin: 12px 0 32px;
}

@media only screen and (max-width: 800px) {
  .Card:hover {
    transform: scale(1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09),
      0px 10px 20px rgba(0, 0, 0, 0.12);
  }
  .active-card,
  .Card {
    padding: 32px !important;
    max-width: 600px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09),
      0px 10px 20px rgba(0, 0, 0, 0.12);
  }
}
